import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import store from './store'

import './i18n'
import 'scss/app.scss'

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <Provider store={store}>
      <App />
    </Provider>
  )
} else {
  createRoot(rootElement).render(
    <Provider store={store}>
      <App />
    </Provider>
  )
}
