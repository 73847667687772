import React, { useState, useRef } from "react"
import i18n from 'i18next'
import { useOutsideClick } from "hooks/useOutsideClick"

import Icon from "components/Icon"

import classes from "classnames"

import styles from './index.module.scss'

const Language = () => {
  const [active, setActive] = useState(JSON.parse(localStorage.getItem('language')) || 'ua')
  const [dropdown, setDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)

  useOutsideClick(dropdownRef, () => setDropdown(false), buttonRef)

  const handleButton = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('language', JSON.stringify(lang))

    setActive(lang)
    setDropdown(!dropdown)
  }

  return (
    <div
      ref={dropdownRef}
      className={
        classes(
          styles.block,
          dropdown && styles.active
        )
      }
    >
      <div
        ref={buttonRef}
        className={styles.checked}
        onClick={() => setDropdown(!dropdown)}
      >
        <div className={styles.text}>
          {active}
        </div>
        <Icon
          className={styles.arrow}
          iconName={'arrow-up'}
          width={10}
          height={6}
        />
      </div>

      <div className={styles.dropdown}>
        <button
          className={
            classes(
              styles.button,
              active === 'ua' && styles.active
            )
          }
          onClick={() => handleButton('ua')}
        >
          <span className={styles.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#ffd700" d="M0 0h640v480H0z" />
                <path fill="#0057b8" d="M0 0h640v240H0z" />
              </g>
            </svg>
          </span>
          UK
        </button>
        <button
          className={
            classes(
              styles.button,
              active === 'ru' && styles.active
            )
          }
          onClick={() => handleButton('ru')}
        >
          <span className={styles.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#fff" d="M0 0h640v480H0z" />
                <path fill="#0039a6" d="M0 160h640v320H0z" />
                <path fill="#d52b1e" d="M0 320h640v160H0z" />
              </g>
            </svg>
          </span>
          RU
        </button>
      </div>
    </div>
  )
}

export default Language
