import React, { useRef } from "react"
import { useOutsideClick } from "hooks/useOutsideClick"

import classes from "classnames"

import Contact from "modules/Contact"
import Powered from "modules/Powered"
import Menu from "modules/Menu"
import Social from "modules/Social"

import styles from './index.module.scss'

const Aside = ({ data, action, buttonRef }) => {
  const navRef = useRef(null)

  useOutsideClick(navRef, () => action(false), buttonRef)

  return (
    <aside
      className={
        classes(
          styles.block,
          data && styles.active
        )
      }
      ref={navRef}
    >
      <Menu data={data} action={action} />
      <Social />
      <Contact />
      <Powered />
    </aside>
  )
}

export default Aside