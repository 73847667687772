import React from "react"
import { useTranslation } from "react-i18next"

import Logo from "components/Logo"
import ContainerFluid from "components/ContainerFluid"
import Container from "components/Container"
import Powered from "modules/Powered"
import Social from "modules/Social"
import Contact from "modules/Contact"

import styles from './index.module.scss'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.block}>
      <ContainerFluid>
        <Container>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <Social />
            </div>
            <div className={styles.center}>
              <Logo />
              <p className={styles.text}>© 2020 - {new Date().getFullYear()} {t('slogan')}</p>
              <Powered />
            </div>
            <div className={styles.right}>
              <Contact />
            </div>
          </div>
        </Container>
      </ContainerFluid>
    </footer>
  )
}

export default Footer
