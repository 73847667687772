import React from 'react'
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import { useIsMain } from "hooks/useIsMain"

import { navigation } from 'constant'

import 'swiper/css'
import 'swiper/css/pagination'

import { handleScroll } from 'helpers/scroll'

import Button from 'components/Button'
import Link from 'components/Link'

import styles from './index.module.scss'

const SLIDES = [
  { id: 8, alt: "Sloboda 8" },
  { id: 1, alt: "Sloboda 1" },
  { id: 2, alt: "Sloboda 2" },
  { id: 4, alt: "Sloboda 4" },
  { id: 5, alt: "Sloboda 5" }
]

const Header = () => {
  const { t } = useTranslation()
  const isMain = useIsMain()

  return (
    <header className={styles.block} id={'header'}>
      <div className={styles.slider}>
        <Swiper
          slidesPerView={1}
          modules={[Autoplay, Pagination]}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
        >
          {SLIDES.map(({ id, alt }) => (
            <SwiperSlide key={id}>
              <picture className={styles.picture}>
                <source srcSet={`/images/header/${id}-small.webp`} media="(max-width: 768px)" />
                <source srcSet={`/images/header/${id}.webp`} />
                <img className={styles.image} src={`/images/header/${id}.webp`} alt={alt} loading="lazy" />
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{t('slogan')}</h1>
        <p className={styles.subtitle}>{t('header-text')}</p>
        {
          isMain &&
          <div className={styles.actions}>
            <Button
              text={t('rules-title')}
              action={(e) => handleScroll(e, '#section-1')}
            />
            <Link
              link={navigation.sectors}
              text={t('button_text_1')}
            />
          </div>
        }
      </div>
    </header>
  )
}

export default Header