import { useEffect } from "react"

export const useOutsideClick = (elementRef, handler, buttonRef) => {
  useEffect(() => {
    const handleClick = (e) => {
      setTimeout(() => {
        if (!elementRef.current) return
        if (buttonRef?.current && buttonRef.current.contains(e.target)) return
        if (!elementRef.current.contains(e.target)) {
          handler(false)
        }
      }, 0)
    }

    document.addEventListener("click", handleClick)

    return () => document.removeEventListener("click", handleClick)
  }, [elementRef, handler, buttonRef])
}
