import React from "react"
import { NavLink } from "react-router-dom"

import styles from './index.module.scss'

const Link = ({ link, text }) => {
  return (
    <NavLink
      to={link}
      className={styles.block}
      aria-label={text}
      title={text}
    >
      <span className={styles.text}>{text}</span>
    </NavLink>
  )
}

export default Link
