import React from "react"
import { useTranslation } from 'react-i18next'

import styles from './index.module.scss'

const Slogan = () => {
  const { t } = useTranslation()

  return (
    <h5 className={styles.block}>
      {t('slogan')}
    </h5>
  )
}

export default Slogan
