import React, { useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { routes } from 'routes'

import { setData } from 'store/actions/dataAction'

import Navigation from 'modules/Navigation'
import Footer from 'modules/Footer'
import Header from 'modules/Header'

import Loader from 'components/Loader'

import styles from './index.module.scss'

const App = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [routeLoading, setRouteLoading] = useState(false)

  useEffect(() => {
    dispatch(setData()).then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) return <Loader />

  return (
    <Router>
      <Navigation />
      <Header />
      <MainContent routeLoading={routeLoading} setRouteLoading={setRouteLoading} />
      <Footer />
    </Router>
  )
}

const MainContent = ({ routeLoading, setRouteLoading }) => {
  const location = useLocation()

  useEffect(() => {
    setRouteLoading(true)
    const timeout = setTimeout(() => {
      setRouteLoading(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [location.pathname])

  return (
    <main className={styles.main}>
      {routeLoading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Suspense>
      )}
    </main>
  )
}

export default App
