import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { navigation } from 'constant'

const Home = lazy(() => import("pages/Home"))
const News = lazy(() => import("pages/News"))
const Donate = lazy(() => import("pages/Donate"))
const Gallery = lazy(() => import("pages/Gallery"))
const Sectors = lazy(() => import("pages/Sectors"))
const Trophies = lazy(() => import("pages/Trophies"))
const Contact = lazy(() => import("pages/Contact"))

export const routes = [
  {
    path: navigation.home,
    element: <Home />
  },
  {
    path: navigation.news,
    element: <News />
  },
  {
    path: navigation.donate,
    element: <Donate />
  },
  {
    path: navigation.gallery,
    element: <Gallery />
  },
  {
    path: navigation.sectors,
    element: <Sectors />
  },
  {
    path: navigation.trophies,
    element: <Trophies />
  },
  {
    path: navigation.contacts,
    element: <Contact />
  },
  {
    path: '*',
    element: <Navigate to={navigation.home} />,
  },
];