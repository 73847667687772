import React from 'react'

import styles from './index.module.scss'

const Loader = () => {
  return (
    <div className={styles.block}>
      <div className={styles.spin} />
    </div>
  )
}

export default Loader
