import React from 'react';

import styles from './index.module.scss'

const Button = ({ text, action = () => {} }) => {
  return (
    <button
      type={'button'}
      className={styles.block}
      aria-label={text}
      title={text}
      onClick={action}
    >
      <span className={styles.text}>{text}</span>
    </button>
  )
}

export default Button;
