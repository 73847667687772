import React from "react"
import { Link } from "react-router-dom"

import styles from './index.module.scss'

const Logo = () => {
  return (
    <Link
      className={styles.block}
      to={'/'}
      aria-label={'Sloboda Fishing'}
    />
  )
}

export default Logo
