import { getData } from 'helpers/api'
import { types } from 'store/actionTypes'

export const setData = () => async dispatch => {
  try {
    const data = await getData('data')

    dispatch({
      type: types.SET_DATA,
      payload: data,
    })

    return data
  } catch (e) {
    console.log(e)
    return null
  }
}
