import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './index.module.scss'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <a
        href={"tel:+380663862573"}
        className={styles.link}
      >
        +38 066-386-25-73
      </a>
      <p className={styles.text}>{t('name')}</p>
    </div>
  )
}

export default Contact;
