export const request = async (method, url, data = null, headers = {}) => {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }

    if (data) {
      options.body = JSON.stringify(data)
    }

    const response = await fetch(`./json/${url}.json`, options)

    if (!response.ok) {
      throw new Error(`Error fetch data: ${response.status}`)
    }

    return await response.json()
  } catch (e) {
    console.error('Error fetch data:', e.message)
    return { error: e.message }
  }
}

export const getData = async (url, headers = {}) => request('GET', url, null, headers)
export const postData = async (url, data, headers = {}) => request('POST', url, data, headers)