import React from "react"
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { navigation } from "constant/index"

import { useIsMain } from "hooks/useIsMain"
import { handleScroll } from "helpers/scroll"

import styles from './index.module.scss'

const MENU_HOME = [
  { path: '#section-0', text: 'menu_link_0' },
  { path: '#section-1', text: 'menu_link_1' },
  { path: '#section-2', text: 'menu_link_2' },
  { path: '#section-3', text: 'menu_link_3' },
  { path: '#section-4', text: 'menu_link_4' },
  { path: '#section-6', text: 'menu_link_6' },
  { path: '#section-7', text: 'menu_link_7' },
]

const MENU_INNER = [
  { path: navigation.home, text: 'menu_link_8' },
  { path: navigation.sectors, text: 'button_text_1' },
  { path: navigation.trophies, text: 'menu_link_2' },
  { path: navigation.gallery, text: 'menu_link_4' },
  { path: navigation.news, text: 'menu_link_6' },
  { path: navigation.contacts, text: 'menu_link_7' },
  { path: navigation.donate, text: 'menu_link_9' },
]

const Menu = ({ data, action }) => {
  const { t } = useTranslation()
  const isMain = useIsMain()

  const handleClick = (event, path) => {
    if (isMain && path.startsWith("#")) {
      handleScroll(event, path)
      action(!data)
    } else {
      action(!data)
    }
  }

  return (
    <menu className={styles.block}>
      {(isMain ? MENU_HOME : MENU_INNER).map(({ path, text }) => (
        <NavLink
          key={path}
          className={styles.link}
          to={isMain && path.startsWith("#") ? "/" : path}
          onClick={(e) => handleClick(e, path)}
        >
          {t(text)}
        </NavLink>
      ))}
    </menu>
  )
}

export default Menu