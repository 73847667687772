import React from 'react'

import Icon from 'components/Icon'

import styles from './index.module.scss'

const LINKS = [
  {
    href: "https://www.facebook.com/groups/1947944298783345/",
    icon: "facebook",
    label: "Facebook"
  },
  {
    href: "https://www.instagram.com/sloboda_fishing/",
    icon: "instagram",
    label: "Instagram"
  },
  {
    href: "https://invite.viber.com/?g2=AQBiqX2jhfXwZ02K1vTcNhSkneQHkeMGBKZsHfkIFikkwSe%2Bz9V5EHMtoOwFGofb&lang=uk",
    icon: "viber",
    label: "Viber"
  },
  {
    href: "mailto:tereschenko23041991@gmail.com?subject=Слобода",
    icon: "mail",
    label: "Mail"
  }
]

const Social = () => {
  return (
    <ul className={styles.block}>
      {LINKS.map(({ href, icon, label }) => (
        <li 
          key={icon} 
          className={styles.item}
        >
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            aria-label={label}
          >
            <Icon 
              iconName={icon} 
              width={32} 
              height={32} 
            />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Social
