import React, { useState, useRef } from "react"

import Logo from "components/Logo"
import Toggle from "components/Toggle"
import Slogan from "modules/Slogan"
import Language from "modules/Language"
import Aside from "modules/Aside"

import styles from './index.module.scss'

const Navigation = () => {
  const [active, setActive] = useState(false)
  const buttonRef = useRef(null)

  return (
    <nav
      className={styles.block}
      role={"navigation"}
    >
      <div className={styles.wrapper}>
        <div className={styles.cell}>
          <Logo />
        </div>
        <div className={styles.cell}>
          <Slogan />
        </div>
        <div className={styles.cell}>
          <Language />
        </div>
        <div className={styles.cell} ref={buttonRef}>
          <Toggle data={active} action={setActive} />
        </div>
        <Aside data={active} action={setActive} buttonRef={buttonRef} />
      </div>
    </nav>
  )
}

export default Navigation
