import React from "react"

import classNames from "classnames"

import styles from './index.module.scss'

const Toggle = ({ data, action }) => {
    return (
        <button
            type={'button'}
            aria-label={'Toggle button'}
            title={'Show menu'}
            className={
                classNames(
                    styles.block, 
                    data && styles.active
                )
            }
            onClick={() => action(!data)}
        >
            <span className={styles.line} />
            <span className={styles.line} />
            <span className={styles.line} />
        </button>
    )
}

export default Toggle;
