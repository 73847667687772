import React from 'react'

import styles from './index.module.scss'

const ContainerFluid = ({children}) => {
  return (
    <div className={styles.block}>
      {children}
    </div>
  )
}

export default ContainerFluid
